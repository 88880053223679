/* eslint-disable import/no-cycle */
import { API } from 'configs';
import {
  store,
  setKarantinaOnprogress,
  setKarantinaCompleted,
  setKarantinaOpen
} from 'modules';
import { handleRequest } from '../helper';

/**
 * service untuk panggil data karantina dalam proses
 * @param {*} payload
 */
export const getKarantinaOnprogress = async (payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        status: 'karantina_inprogress',
        no_pagination: 1
      }
    };

    store.dispatch(setKarantinaOnprogress({ isLoading: true, isError: false }));

    const res = await handleRequest(API.karantina(requestPayload));

    const { data } = res;
    store.dispatch(
      setKarantinaOnprogress({ isLoading: false, isError: false, data })
    );

    return data;
  } catch (err) {
    store.dispatch(setKarantinaOnprogress({ isLoading: false, isError: true }));
    throw err;
  }
};

/**
 * service untuk panggil data karantina dalam proses penerimaan hewan
 * @param {*} payload
 */
export const getKarantinaOpen = async (payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        status: 'karantina_open',
        no_pagination: 1
      }
    };

    store.dispatch(setKarantinaOpen({ isLoading: true, isError: false }));

    const res = await handleRequest(API.karantina(requestPayload));

    const { data } = res;
    store.dispatch(
      setKarantinaOpen({ isLoading: false, isError: false, data })
    );

    return data;
  } catch (err) {
    store.dispatch(setKarantinaOpen({ isLoading: false, isError: true }));
    throw err;
  }
};

/**
 * service untuk panggil data karantina selesai
 * @param {*} payload
 */
export const getKarantinaCompleted = async (payload = {}, testlab = false) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        status: !testlab ? 'karantina_completed' : 'testlab_completed',
        no_pagination: 1
      }
    };

    store.dispatch(setKarantinaCompleted({ isLoading: true, isError: false }));

    const res = await handleRequest(API.karantina(requestPayload));

    const { data } = res;
    store.dispatch(
      setKarantinaCompleted({ isLoading: false, isError: false, data })
    );

    return data;
  } catch (err) {
    store.dispatch(setKarantinaCompleted({ isLoading: false, isError: true }));
    throw err;
  }
};

/**
 * services untuk memanggil detail karantina
 * @param {*} id
 * @param {*} payload
 */
export const getKarantinaById = async (id, payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      path: `${id}`
    };
    const res = await handleRequest(API.karantina(requestPayload));

    const { data } = res;

    return data;
  } catch (err) {
    throw err;
  }
};

export const getPenangananKarantina = async (karantinaId, payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        karantina_id: karantinaId
      }
    };
    const res = await handleRequest(API.penangananKarantina(requestPayload));

    const { data } = res;

    return data;
  } catch (err) {
    throw err;
  }
};

export const createPenangananKarantina = async (payload = {}) => {
  try {
    const res = await handleRequest(API.createPenangananKarantina(payload));

    return res;
  } catch (err) {
    throw err;
  }
};

export const updatePenangananKarantina = async (id, payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      path: id
    };
    const res = await handleRequest(
      API.updatePenangananKarantina(requestPayload)
    );

    return res;
  } catch (err) {
    throw err;
  }
};

export const deletePenangananKarantina = async (id, payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      path: id
    };

    const res = await handleRequest(
      API.deletePenangananKarantina(requestPayload)
    );

    return res;
  } catch (err) {
    throw err;
  }
};

export const sendAlert = async karantinaId => {
  try {
    const requestPayload = {
      body: {
        karantina_id: karantinaId
      }
    };

    const res = await handleRequest(API.alert(requestPayload));

    return res;
  } catch (err) {
    throw err;
  }
};

export const submitPemeriksaanAwal = async (payload = {}) => {
  try {
    const res = await handleRequest(API.createPemeriksaanAwal(payload));
    return res;
  } catch (err) {
    throw err;
  }
};


export const submitResponPermohonan = async (payload = {}) => {
  try {
    const res = await handleRequest(API.responPermohonan(payload));
    return res;
  } catch (err) {
    throw err;
  }
};