import actionType from './const';

const initState = {
  all: []
};

const penerimaanHewan = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_PENERIMAAN_HEWAN:
      return {
        ...state,
        all: action.value
      };
    case actionType.CLEAR_PENERIMAAN_HEWAN:
      return initState;
    default:
      return state;
  }
};

export default penerimaanHewan;
