import actionType from './const';

const initState = {
  itemKarantina: [],
  petugasKH: []
};

const client = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_CLIENT_ITEM_KARANTINA:
      return {
        ...state,
        itemKarantina: action.value
      };
    case actionType.SET_CLIENT_PETUGAS_KH:
      return {
        ...state,
        petugasKH: action.value
      };
    case actionType.CLEAR_CLIENT:
      return initState;
    default:
      return state;
  }
};

export default client;
