import actionType from './const';

const initialState = {};

/**
 * @param { initialState } state
 * @param { api, type, value } action
 */

const request = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REQUEST_API:
      return {
        ...state,
        [action.api]: {
          ...state[action.api],
          isLoading: true,
          isError: false
        }
      };
    case actionType.REQUEST_API_SUCCESS:
      return {
        ...state,
        [action.api]: {
          ...state[action.api],
          isLoading: false,
          isError: false,
          data: action.value
        }
      };
    case actionType.REQUEST_API_FAILED:
      return {
        ...state,
        [action.api]: {
          ...state[action.api],
          isLoading: false,
          isError: true
        }
      };
    case actionType.CLEAR_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default request;
