import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import './index.css';
import { Provider } from 'react-redux';
import { store } from 'modules';
import appEnv from 'configs/appConfig';
import App from './App';
import * as serviceWorker from './serviceWorker';

const RootApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

firebase.initializeApp(appEnv.firebaseConfig);
ReactDOM.render(<RootApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
