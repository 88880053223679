/* eslint-disable import/no-cycle */
import { API } from 'configs';
import { store, setAllUser, setInactiveUser, setDoctors } from 'modules';
import { handleRequest } from '../helper';

/**
 * a service for call get all user with pagination
 * @param {*} payload
 */
export const getAllUser = async (payload = {}) => {
  try {
    store.dispatch(setAllUser({ isLoading: true, isError: false }));

    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        no_pagination: 1
      }
    };

    const res = await handleRequest(API.users(requestPayload));

    const { data } = res;

    store.dispatch(setAllUser({ isLoading: false, isError: false, data }));

    return data;
  } catch (err) {
    store.dispatch(setAllUser({ isLoading: false, isError: true }));
    throw err;
  }
};

/**
 * a service for call get inactive user with pagination
 * @param {*} payload
 */
export const getInactiveUser = async (payload = {}) => {
  try {
    store.dispatch(setInactiveUser({ isLoading: true, isError: false }));

    const res = await handleRequest(
      API.users({
        ...payload,
        params: {
          ...payload.params,
          user_status: 'inactive',
          no_pagination: 1
        }
      })
    );

    const { data } = res;

    store.dispatch(setInactiveUser({ isLoading: false, isError: false, data }));

    return data;
  } catch (err) {
    store.dispatch(setInactiveUser({ isLoading: false, isError: true }));
    throw err;
  }
};

/**
 * a service for call get user by id
 * @param {*} userId
 */
export const getUserById = async userId => {
  try {
    const res = await handleRequest(
      API.users({
        path: userId
      })
    );

    const { data } = res;

    return data;
  } catch (err) {
    throw err;
  }
};

/**
 * a service for user creation
 * @param {*} payload
 */
export const createUser = async (payload = {}) => {
  try {
    const res = await handleRequest(API.createUser(payload));

    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * a service for edit user
 * @param {*} payload
 */
export const updateUser = async (userId, payload = {}) => {
  try {
    const res = await handleRequest(
      API.editUser({
        ...(userId && { path: `${userId}` }),
        ...payload
      })
    );
    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * a service for deleting user
 * @param {*} payload
 */
export const deleteUser = async (userId, payload = {}) => {
  try {
    const res = await handleRequest(
      API.deleteUser({
        ...(userId && { path: `${userId}` }),
        ...payload
      })
    );
    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * a service for change user status
 * @param {*} payload
 */
export const changeUserStatus = async (userId, payload = {}) => {
  try {
    const res = await handleRequest(
      API.changeUserStatus({
        ...(userId && { path: `${userId}/status` }),
        ...payload
      })
    );
    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * a service for user doctors
 * @param {*} payload
 */
export const getDoctors = async (payload = {}) => {
  try {
    const res = await handleRequest(
      API.users({
        ...payload,
        params: {
          ...payload.params,
          no_pagination: 1
        }
      })
    );
    const { data } = res;

    store.dispatch(setDoctors(data));

    return data;
  } catch (err) {
    throw err;
  }
};
