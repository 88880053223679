import { API } from 'configs';
import { setProfile, store } from 'modules';
import { handleRequest } from '../helper';

/**
 * get profile service
 * @param {*} payload
 */
export const getProfile = async payload => {
  try {
    const res = await handleRequest(API.profile(payload));
    const { data } = res;

    if (data) {
      store.dispatch(setProfile(data));
    }

    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * update profile service
 * @param {*} payload
 */
export const updateProfile = async payload => {
  try {
    const res = await handleRequest(API.changeProfile(payload));
    return res;
  } catch (err) {
    throw err;
  }
};

/**
 * update password
 * @param {*} payload
 */
export const updatePassword = async payload => {
  try {
    const res = await handleRequest(
      API.changePassword({ path: 'password', ...payload })
    );

    return res;
  } catch (err) {
    throw err;
  }
};
