const actionType = {
  SET_KARANTINA: 'SET_KARANTINA',
  SET_KARANTINA_HISTORY: 'SET_KARANTINA_HISTORY',
  CLEAR_KARANTINA: 'CLEAR_KARANTINA',
  SET_REPORT: 'SET_REPORT',
  CLEAR_REPORT: 'CLEAR_REPORT',

  SET_KARANTINA_ONPROGRESS: 'SET_KARANTINA_ONPROGRESS',
  CLEAR_KARANTINA_ONPROGRESS: 'CLEAR_KARANTINA_ONPROGRESS',

  SET_KARANTINA_OPEN: 'SET_KARANTINA_OPEN',
  CLEAR_KARANTINA_OPEN: 'CLEAR_KARANTINA_OPEN',

  SET_KARANTINA_COMPLETED: 'SET_KARANTINA_COMPLETED',
  CLEAR_KARANTINA_COMPLETED: 'CLEAR_KARANTINA_COMPLETED',

  RESET_REDUCER_KARANTINA: 'RESET_REDUCER_KARANTINA'
};

export default actionType;
