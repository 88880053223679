import { createSelector } from 'reselect';

export const karantinaSelector = state => state.karantina.all;
export const historyKarantinaSelector = state => state.karantina.histories;
export const reportPemantauanHarian = state => state.karantina.report;

export const openedKarantinaSelector = createSelector(
  karantinaSelector,
  karantina => karantina.filter(data => data.karantina_status === 'open')
);

export const ongoingKarantinaSelector = createSelector(
  karantinaSelector,
  karantina => karantina.filter(data => data.karantina_status === 'ongoing')
);

export const ongoingKarantinaSortedSelector = createSelector(
  karantinaSelector,
  karantina =>
    karantina
      .filter(
        data =>
          data.karantina_status === 'ongoing' ||
          data.karantina_status === 'open'
      )
      .sort((next, prev) => prev.status - next.status)
);

export const pendingKarantinaReportSelector = createSelector(
  ongoingKarantinaSelector,
  karantina => karantina.filter(data => data.report_status === 0)
);

export const reportedKarantinaReportSelector = createSelector(
  ongoingKarantinaSelector,
  karantina => karantina.filter(data => data.report_status === 1)
);

// Pengajuan Lab
export const openedPengajuanLab = createSelector(
  ongoingKarantinaSelector,
  karantina => karantina.filter(data => data.testlab_status === 'open')
);

export const ongoingPengajuanLab = createSelector(
  ongoingKarantinaSelector,
  karantina =>
    karantina.filter(
      data =>
        data.testlab_status === 'ongoing' ||
        data.testlab_status === 'waiting' ||
        data.testlab_status === 'waiting_report'
    )
);

export const ongoingUjiLab = createSelector(
  ongoingKarantinaSelector,
  karantina => karantina.filter(data => data.testlab_status === 'ongoing')
);

export const completePengajuanLab = createSelector(
  karantinaSelector,
  karantina => karantina.filter(data => data.testlab_status === 'completed')
);

export const waitingPengajuanLab = createSelector(
  ongoingKarantinaSelector,
  karantina => karantina.filter(data => data.testlab_status === 'waiting')
);

export const pelepasanKarantina = createSelector(
  ongoingKarantinaSelector,
  karantina =>
    karantina.filter(
      data =>
        data.karantina_status === 'ongoing' &&
        data.testlab_status === 'completed'
    )
);

export const completeKarantina = createSelector(
  karantinaSelector,
  karantina =>
    karantina.filter(
      data =>
        data.karantina_status === 'completed' &&
        data.testlab_status === 'completed'
    )
);

export const waitingReport = createSelector(
  ongoingKarantinaSelector,
  karantina =>
    karantina.filter(data => data.testlab_status === 'waiting_report')
);

export const listKarantinaUjiLabSelected = createSelector(
  ongoingKarantinaSelector,
  karantina => karantina.filter(data => data.testlab_status === 'ongoing')
);

/**
 * NEW CODE
 */

export const karantinaOnprogressSelector = state =>
  state.karantina.karantinaOnprogress;

export const karantinaCompletedSelector = state =>
  state.karantina.karantinaCompleted;

/**
 * penerimaan hewan
 */
export const karantinaOpenSelector = createSelector(
  karantinaOnprogressSelector,
  karantina => {
    const data = karantina.data.filter(val => val.karantina_status === 'open');
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaOngoingSelector = createSelector(
  karantinaOnprogressSelector,
  karantina => {
    const data = karantina.data.filter(
      val => val.karantina_status === 'ongoing'
    );
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaPendingReport = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.report_status === 0);
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaSuccessReport = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.report_status === 1);
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaPendingLab = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.testlab_status === 'open');
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaOnprogressLab = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val =>
        val.testlab_status === 'ongoing' ||
        val.testlab_status === 'waiting' ||
        val.testlab_status === 'waiting_kajiulang' ||
        val.testlab_status === 'waiting_report'
    );
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaCompletedLab = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val => val.testlab_status === 'completed'
    );
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaResponPermohonan = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.testlab_status === 'waiting');
    return {
      ...karantina,
      data
    };
  }
);


export const karantinaResponPermohonanSelesai = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val =>
        val.testlab_status === 'ongoing' ||
        val.testlab_status === 'waiting_kajiulang' ||
        val.testlab_status === 'waiting_report'
    );
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaKajiUlangKesiapan = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.testlab_status === 'waiting_kajiulang');
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaKajiUlangKesiapanSelesai = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val =>
        val.testlab_status === 'ongoing' ||
        val.testlab_status === 'waiting_report'
    );
    return {
      ...karantina,
      data
    };
  }
);


export const karantinaDalamUjiLab = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.testlab_status === 'ongoing');
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaLaporanAnalisaLab = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val => val.testlab_status === 'waiting_report'
    );
    return {
      ...karantina,
      data
    };
  }
);

// Dashboard
export const terlambatPelepasan = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.pelepasan_status === 3);
    return {
      ...karantina,
      data
    };
  }
);

export const pelepasanHariIni = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.pelepasan_status === 2);
    return {
      ...karantina,
      data
    };
  }
);

export const PelepasanMingguIni = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(val => val.pelepasan_status === 1);
    return {
      ...karantina,
      data
    };
  }
);

export const SummaryKarantinaBerlangsung = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val => val.pelepasan_status === 1 || val.pelepasan_status === 2
    );
    return {
      ...karantina,
      data
    };
  }
);

export const karantinaPelepasan = createSelector(
  karantinaOngoingSelector,
  karantina => {
    const data = karantina.data.filter(
      val =>
        val.testlab_status !== 'ongoing' &&
        val.testlab_status !== 'waiting' &&
        val.testlab_status !== 'waiting_kajiulang' &&
        val.testlab_status !== 'waiting_report'
    );
    return {
      ...karantina,
      data
    };
  }
);
