const actionType = {
  SET_PERMOHONAN: 'SET_PERMOHONAN',
  CLEAR_PERMOHONAN: 'CLEAR_PERMOHONANAN',

  SET_PERMOHONAN_PENDING: 'SET_PERMOHONAN_PENDING',
  CLEAR_PERMOHONAN_PENDING: 'CLEAR_PERMOHONAN_PENDING',

  SET_PERMOHONAN_APPROVED: 'SET_PERMOHONAN_APPROVED',
  CLEAR_PERMOHONAN_APPROVED: 'CLEAR_PERMOHONAN_PENDING',

  SET_PERMOHONAN_REJECTED: 'SET_PERMOHONAN_REJECTED',
  CLEAR_PERMOHONAN_REJECTED: 'CLEAR_PERMOHONAN_REJECTED',

  RESET_REDUCER_PERMOHONAN: 'RESET_REDUCER_PERMOHONAN'
};

export default actionType;
