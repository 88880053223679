import actionType from './const';
import { reduxApiRequest } from '../request/action';

export const setIkhLab = value => ({
  type: actionType.SET_IKH_LAB,
  value
});

export const clearIkhLab = () => ({
  type: actionType.CLEAR_IKH_LAB
});

export const fetchIkhLabs = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getIkhLab',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setIkhLab(data));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchIkhLab = (id = null, payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getIkhLab',
    ...(id && { path: id }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchCreateIkhLab = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'addIkhLab',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchUpdateIkhLab = (
  id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'updateIkhLab',
    ...(id && { path: id }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchDeleteIkhLab = (
  id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'deleteIkhLab',
    ...(id && { path: id }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
