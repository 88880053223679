import actionType from './const';

export const setUsers = value => ({
  type: actionType.SET_USERS,
  value
});

export const clearUsers = () => ({
  type: actionType.CLEAR_USERS
});

/**
 * =============================
 * NEW ACTION
 * =============================
 */

export const setAllUser = value => ({
  type: actionType.SET_ALL_USERS,
  value
});

export const clearAllUser = () => ({
  type: actionType.CLEAR_ALL_USERS
});

export const setInactiveUser = value => ({
  type: actionType.SET_INACTIVE_USERS,
  value
});

export const clearInactiveUser = () => ({
  type: actionType.CLEAR_INACTIVE_USERS
});

export const resetUserReducer = () => ({
  type: actionType.RESET_USER_REDUCER
});

export const setDoctors = value => ({
  type: actionType.SET_DOCTORS,
  value
});

export const clearDoctors = () => ({
  type: actionType.CLEAR_DOCTORS
});
