import actionType from './const';

const initialState = {
  all: [],
  allUser: {
    isLoading: false,
    isError: false,
    data: []
  },
  inactiveUser: {
    isLoading: false,
    isError: false,
    data: []
  },
  doctors: []
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_USERS:
      return {
        ...state,
        all: action.value
      };
    case actionType.CLEAR_USERS:
      return {
        ...state,
        all: []
      };
    case actionType.SET_ALL_USERS: {
      return {
        ...state,
        allUser: {
          ...state.allUser,
          ...action.value
        }
      };
    }
    case actionType.CLEAR_ALL_USERS: {
      return {
        ...state,
        allUser: initialState.allUser
      };
    }
    case actionType.SET_INACTIVE_USERS: {
      return {
        ...state,
        inactiveUser: {
          ...state.inactiveUser,
          ...action.value
        }
      };
    }
    case actionType.CLEAR_INACTIVE_USERS: {
      return {
        ...state,
        inactiveUser: initialState.allUser
      };
    }
    case actionType.SET_DOCTORS:
      return {
        ...state,
        doctors: action.value
      };
    case actionType.CLEAR_DOCTORS:
      return {
        ...state,
        doctors: []
      };
    case actionType.RESET_USER_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default user;
