/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

// reducer
import toast from './toast/reducer';
import loading from './loading/reducer';
import request from './request/reducer';
import profile from './profile/reducer';
import user from './user/reducer';
import itemKarantina from './itemKarantina/reducer';
import jenisPenanganan from './jenisPenanganan/reducer';
import petugasKH from './petugasKH/reducer';
import ikhLab from './ikhLab/reducer';
import client from './client/reducer';
import permohonan from './permohonan/reducer';
import karantina from './karantina/reducer';
import penerimaanHewan from './penerimaanHewan/reducer';
import notification from './notification/reducer';
import activity from './activity/reducer';

export const reducer = combineReducers({
  loading,
  profile,
  request,
  toast,
  user,
  itemKarantina,
  jenisPenanganan,
  petugasKH,
  ikhLab,
  client,
  permohonan,
  karantina,
  penerimaanHewan,
  notification,
  activity
});

// action
export * from './toast/action';
export * from './notification/action';
export * from './loading/action';
export * from './request/action';
export * from './profile/action';
export * from './user/action';
export * from './itemKarantina/action';
export * from './jenisPenanganan/action';
export * from './petugasKH/action';
export * from './ikhLab/action';
export * from './client/action';
export * from './permohonan/action';
export * from './karantina/action';
export * from './penerimaanHewan/action';
export * from './activity/action';

// selector
export * from './user/selector';
export * from './notification/selector';
export * from './petugasKH/selector';
export * from './itemKarantina/selector';
export * from './permohonan/selector';
export * from './ikhLab/selector';
export * from './karantina/selector';
export * from './activity/selector';
