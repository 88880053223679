import actionType from './const';

const initState = {
  all: [],
  tempatTugas: [],
  selectedTempatTugas: null
};

const petugasKH = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_PETUGAS_KH:
      return {
        ...state,
        all: action.value
      };
    case actionType.SET_TEMPAT_TUGAS:
      return {
        ...state,
        tempatTugas: action.value
      };
    case actionType.SET_SELECTED_TEMPAT_TUGAS:
      return {
        ...state,
        selectedTempatTugas: action.value
      };
    case actionType.CLEAR_SELECTED_TEMPAT_TUGAS:
      return {
        ...state,
        selectedTempatTugas: null
      };
    case actionType.CLEAR_PETUGAS_KH:
      return initState;
    default:
      return state;
  }
};

export default petugasKH;
