import { createSelector } from 'reselect';

export const permohonanSelector = state => state.permohonan.all;

export const pendingPermohonanSelector = createSelector(
  permohonanSelector,
  data => data.filter(val => val.status === 'pending')
);

export const approvedPermohonanSelector = createSelector(
  permohonanSelector,
  data => data.filter(val => val.status === 'approve')
);

export const rejectedPermohonanSelector = createSelector(
  permohonanSelector,
  data => data.filter(val => val.status === 'reject')
);

/**
 * NEW CODE
 */

/**
 * selector untuk data pending
 * @param {*} state
 */
export const permohonanPendingSelector = state => state.permohonan.pendingData;

/**
 * selector untu data yang disetujui
 * @param {*} state
 */
export const permohonanApprovedSelector = state =>
  state.permohonan.approvedData;

/**
 * selector untuk data yang ditolak
 * @param {*} state
 */
export const permohonanRejectedSelector = state =>
  state.permohonan.rejectedData;
