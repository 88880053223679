const actionType = {
  SET_USERS: 'SET_USERS',
  CLEAR_USERS: 'CLEAR_USERS',

  SET_ALL_USERS: 'SET_ALL_USERS',
  CLEAR_ALL_USERS: 'CLEAR_ALL_USERS',

  SET_INACTIVE_USERS: 'SET_INACTIVE_USERS',
  CLEAR_INACTIVE_USERS: 'CLEAR_INACTIVE_USERS',

  SET_DOCTORS: 'SET_DOCTORS',
  CLEAR_DOCTORS: 'CLEAR_DOCTORS',

  RESET_USER_REDUCER: 'RESET_USER_REDUCER'
};

export default actionType;
