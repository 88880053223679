import actionType from './const';

const initState = {
  all: [],
  report: [],
  histories: [],
  karantinaOnprogress: {
    isLoading: false,
    isError: false,
    data: []
  },
  karantinaCompleted: {
    isLoading: false,
    isError: false,
    data: []
  },
  karantinaOpen: {
    isLoading: false,
    isError: false,
    data: []
  }
};

const karantina = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_KARANTINA:
      return {
        ...state,
        all: action.value
      };
    case actionType.SET_KARANTINA_HISTORY:
      return {
        ...state,
        histories: action.value
      };
    case actionType.CLEAR_KARANTINA:
      return initState;
    case actionType.SET_REPORT:
      return {
        ...state,
        report: action.value
      };
    case actionType.SET_KARANTINA_OPEN:
      return {
        ...state,
        karantinaOpen: {
          ...state.karantinaOnprogress,
          ...action.value
        }
      };
    case actionType.CLEAR_KARANTINA_OPEN:
      return {
        ...state,
        karantinaOpen: initState.karantinaOnprogress
      };

    case actionType.SET_KARANTINA_ONPROGRESS:
      return {
        ...state,
        karantinaOnprogress: {
          ...state.karantinaOnprogress,
          ...action.value
        }
      };
    case actionType.CLEAR_KARANTINA_ONPROGRESS:
      return {
        ...state,
        karantinaOnprogress: initState.karantinaOnprogress
      };
    case actionType.SET_KARANTINA_COMPLETED:
      return {
        ...state,
        karantinaCompleted: {
          ...state.karantinaCompleted,
          ...action.value
        }
      };
    case actionType.CLEAR_KARANTINA_COMPLETED:
      return {
        ...state,
        karantinaCompleted: initState.karantinaCompleted
      };

    case actionType.CLEAR_REPORT:
      return {
        ...state,
        report: []
      };

    case actionType.RESET_REDUCER_KARANTINA:
      return initState;
    default:
      return state;
  }
};

export default karantina;
