/* eslint-disable import/no-cycle */
import { API } from 'configs';
import {
  store,
  setPermohonanPending,
  setPermohonanApproved,
  setPermohonanRejected
} from 'modules';
import { handleRequest } from '../helper';

/**
 * untuk memanggil permohonan dengan status pending
 * @param {*} payload
 */
export const getPermohonanPending = async (payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        status: 'pending',
        no_pagination: 1
      }
    };

    store.dispatch(
      setPermohonanPending({
        isLoading: true,
        isError: false
      })
    );

    const response = await handleRequest(API.permohonan(requestPayload));

    const { data } = response;

    store.dispatch(
      setPermohonanPending({
        isLoading: false,
        isError: false,
        data
      })
    );

    return data;
  } catch (err) {
    store.dispatch(
      setPermohonanPending({
        isLoading: false,
        isError: true
      })
    );
    throw err;
  }
};

/**
 * untuk memanggil permohonan dengan status disetujui
 * @param {*} payload
 */
export const getPermohonanApproved = async (payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        status: 'approved',
        no_pagination: 1
      }
    };

    store.dispatch(
      setPermohonanApproved({
        isLoading: true,
        isError: false
      })
    );

    const response = await handleRequest(API.permohonan(requestPayload));

    const { data } = response;

    store.dispatch(
      setPermohonanApproved({
        isLoading: false,
        isError: false,
        data
      })
    );

    return data;
  } catch (err) {
    store.dispatch(
      setPermohonanApproved({
        isLoading: false,
        isError: true
      })
    );
    throw err;
  }
};

/**
 * untuk memanggil permohonan dengan status ditolak
 * @param {*} payload
 */
export const getPermohonanRejected = async (payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      params: {
        ...payload.params,
        status: 'rejected',
        no_pagination: 1
      }
    };

    store.dispatch(
      setPermohonanRejected({
        isLoading: true,
        isError: false
      })
    );

    const response = await handleRequest(API.permohonan(requestPayload));

    const { data } = response;

    store.dispatch(
      setPermohonanRejected({
        isLoading: false,
        isError: false,
        data
      })
    );

    return data;
  } catch (err) {
    store.dispatch(
      setPermohonanRejected({
        isLoading: false,
        isError: true
      })
    );
    throw err;
  }
};

/**
 * untuk mendapatkan detail permohonan
 * @param {*} permohonanId
 * @param {*} payload
 */
export const getPermohonanById = async (permohonanId, payload = {}) => {
  try {
    const requestPayload = {
      ...payload,
      path: `${permohonanId}`
    };

    const response = await handleRequest(API.permohonan(requestPayload));

    const { data } = response;

    return data;
  } catch (err) {
    throw err;
  }
};
