import actionType from './const';

export const setActtivity = value => ({
  type: actionType.SET_ACTIVITY,
  value
});

export const clearActivity = () => ({
  type: actionType.CLEAR_ACTIVITY
});
