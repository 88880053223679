import { createSelector } from 'reselect';

// all users: DEPRECATED
export const usersSelector = state => state.user.all;

// inactive users
export const inactiveUsersSelector = createSelector(
  usersSelector,
  users => users.filter(user => user.status === 'inactive')
);

/**
 * ============================================
 * NEW SELECTOR
 * ============================================
 */

/**
 * all user selector
 * @param {*} state
 */
export const allUserSelector = state => state.user.allUser;

/**
 * inactive user selector
 * @param {*} state
 */
export const inactiveUserSelector = state => state.user.inactiveUser;

/**
 * doctor user selector user selector
 * @param {*} state
 */

export const doctorState = state => state.user.doctors;

export const doctorsSelector = createSelector(
  doctorState,
  users =>
    users.filter(
      user =>
        (user.status === 'active' && user.role.name === 'ikh') ||
        user.role.name === 'lab'
    )
);
