/* eslint-disable import/no-cycle */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {
  clearProfile,
  clearPetugasKH,
  clearUsers,
  clearItemKarantina,
  clearJenisPenanganan,
  clearFormPengajuan,
  clearRequest,
  clearKarantina,
  clearNotification,
  reducer
} from './reducer';

export const resetReducer = () => dispatch => {
  dispatch(clearUsers());
  dispatch(clearProfile());
  dispatch(clearRequest());
  dispatch(clearKarantina());
  dispatch(clearNotification());
  dispatch(clearItemKarantina());
  dispatch(clearJenisPenanganan());
  dispatch(clearFormPengajuan());
  dispatch(clearPetugasKH());
};

export * from './reducer';

export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk))
);
