/**
 * folder appConfig berisi configurasi yang akan dibutuhkan dan diimport
 * pada beberapa module yang memerlukan dari config disini
 * environment: dev, production, dll
 */
export const appConfig = {
  appName: "SIM LAB IKH",
  appFullname: "Sistem Manajemen Karantina Hewan",
  appDescription: "Sistem Manajemen Karantina Hewan",
  activeConfig: "production",
  version: "2.1.2",
  author: "Kabayan Coding"
};

const appEnvirontment = {
  dev: {
    url: {
      api: "http://simlabkh.com/api",
      assets: "http://simlabkh.com",
      origin: "http://simlabkh.com"
    },
    firebaseConfig: {
      apiKey: "AIzaSyAKtb02hPfvpjYtQn7CAC90m9m9IfkoXxE",
      authDomain: "simkh-81805.firebaseapp.com",
      databaseURL: "https://simkh-81805.firebaseio.com",
      projectId: "simkh-81805",
      storageBucket: "simkh-81805.appspot.com",
      messagingSenderId: "1061844459372",
      appId: "1:1061844459372:web:bc4d7d1a91e4fcea379d49"
    }
  },
  production: {
    url: {
      api: "http://apisimlabkh.bbkpsoetta.com/api",
      assets: "http://apisimlabkh.bbkpsoetta.com",
      origin: "http://apisimlabkh.bbkpsoetta.com"
    },
    firebaseConfig: {
      apiKey: "AIzaSyAKtb02hPfvpjYtQn7CAC90m9m9IfkoXxE",
      authDomain: "simkh-81805.firebaseapp.com",
      databaseURL: "https://simkh-81805.firebaseio.com",
      projectId: "simkh-81805",
      storageBucket: "simkh-81805.appspot.com",
      messagingSenderId: "1061844459372",
      appId: "1:1061844459372:web:bc4d7d1a91e4fcea379d49"
    }
  }
};

export default { ...appEnvirontment[appConfig.activeConfig] };
