/* eslint-disable import/no-cycle */
import axios from "axios";
import { Tools } from "utils";
// eslint-disable-next-line import/no-cycle

export const apiInstance = axios.create({
  baseURL: "",
  timeout: 60000,
  validateStatus: status => status >= 200 && status < 300
});

class ApiRequest {
  static get(route, token = false) {
    return payload => this.request("GET", route, payload, token);
  }

  static put(route, token = false) {
    return payload => this.request("PUT", route, payload, token);
  }

  static post(route, token = false) {
    return payload => this.request("POST", route, payload, token);
  }

  static delete(route, token = false) {
    return payload => this.request("DELETE", route, payload, token);
  }

  static patch(route, token = false) {
    return payload => this.request("PATCH", route, payload, token);
  }

  static resolveParams(params) {
    const paramsResult = [];
    Object.keys(params).map(e => paramsResult.push(`${e}=${params[e]}`));
    return paramsResult.join("&");
  }

  static request(method, route, payload = {}, token = false) {
    return new Promise((resolve, reject) => {
      const path = payload.path ? `/${payload.path}` : "";
      const params = payload.params
        ? `?${this.resolveParams(payload.params)}`
        : "";
      const customUrl = payload.url ? payload.url : "";
      const baseHeaders = {
        "Content-Type":
          payload.type === "form-data"
            ? "multipart/form-data"
            : "application/json"
      };
      if (token) {
        const userToken = Tools.getAuthToken();
        baseHeaders.Authorization = `bearer ${userToken || ""}`;
      }

      const requestPayload = {
        url: customUrl.length > 0 ? customUrl : route + path + params,
        method,
        headers: payload.headers
          ? { ...baseHeaders, ...payload.headers }
          : baseHeaders,
        data: payload.body ? payload.body : {}
      };

      console.log("requestPayload", requestPayload);

      apiInstance
        .request(requestPayload)
        .then(res => {
          console.log("response", res);

          if (res && res.data) {
            const response = {
              method,
              ...res.data
            };
            resolve(response);
          } else if (res) {
            resolve(res);
          } else {
            reject(
              new Error({
                status: false,
                message: "Koneksi/Server Bermasalah"
              })
            );
          }
        })
        .catch(err => {
          console.log("error", err);
          if (err && err.response && err.response.data) {
            reject(err.response.data);
          } else if (err && err.response) {
            const errs = {
              statusCode: err.response.status,
              ...err.response
            };
            console.log("error ", errs);
            reject(errs);
          } else if (err) {
            reject(err);
          }
        });
    });
  }
}

export default ApiRequest;
