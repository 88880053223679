import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/id';
import NotificationSystem from 'react-notification-system';
import LoadingBar from 'react-top-loading-bar';
import cx from 'classnames';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);
// import library css

export * from 'react';
export * from 'react-router-dom';
export * from 'react-redux';
export {
  _,
  cx,
  React,
  moment,
  DataTable,
  PropTypes,
  LoadingBar,
  Select,
  DatePicker,
  NotificationSystem,
  ReactSwal,
  withReactContent
};
