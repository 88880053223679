import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { PDFViewer } from '@react-pdf/renderer';

const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const MainMenu = React.lazy(() => import('./views/Pages/MainMenu'));
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Laporan = React.lazy(() => import('./views/Pages/Laporan'));


const loading = () => <div className="animated fadeIn pt-3 text-center"></div>;


function App () {
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path="/laporan"
            name="Laporan"
            render={props => <Laporan {...props} />}
          />
          <Route
            exact
            path="/login/:type"
            name="Silahkan Masuk"
            render={props => <Login {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={props => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={props => <Page500 {...props} />}
          />
          <Route
            path="/main-menu"
            name="Main Menu"
            render={props => <MainMenu {...props} />}
          />
          <Route
            path="/:layoutType"
            name="Home"
            render={props => <DefaultLayout {...props} />}
          />
          <Route
            path="/"
            name="Main Menu"
            render={props => <MainMenu {...props} />}
          />
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default App;
