export const setAuthToken = value => {
  localStorage.setItem('bkp_token', value);
};

export const getAuthToken = () => {
  const token = localStorage.getItem('bkp_token');
  return token;
};

export const deleteAuthToken = () => {
  const token = localStorage.removeItem('bkp_token');
  return token;
};

export const setInbox = (value = '') => {
  try {
    localStorage.setItem('bkp_inbox', JSON.stringify(value));
  } catch (err) {
    throw err;
  }
  return true;
};

export const getInbox = () => {
  const inbox = localStorage.getItem('bkp_inbox');
  return JSON.parse(inbox);
};

export const deleteInbox = () => localStorage.removeItem('bkp_inbox');

export const setNotification = (value = '') => {
  try {
    localStorage.setItem('bkp_notification', JSON.stringify(value));
  } catch (err) {
    throw err;
  }
  return true;
};

export const getNotification = () => {
  const inbox = localStorage.getItem('bkp_notification');
  return JSON.parse(inbox);
};

export const deleteNotification = () =>
  localStorage.removeItem('bkp_notification');
