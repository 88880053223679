import actionType from './const';
import { reduxApiRequest } from '../request/action';

export const setNotification = value => ({
  type: actionType.SET_NOTIFICATION,
  value
});

export const setInbox = value => ({
  type: actionType.SET_INBOX,
  value
});

export const clearNotification = () => ({
  type: actionType.CLEAR_NOTIFICATION
});

export const clearInbox = () => ({
  type: actionType.CLEAR_INBOX
});

export const fetchGetNotification = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getNotification',
    ...payload
  };
  try {
    const response = await dispatch(
      reduxApiRequest(requestPayload, true, false)
    );
    const { data } = response;
    dispatch(setNotification(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchReadNotification = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'readNotification',
    ...payload,
    path: `read/${payload.path}`
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload, false));
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendAlert = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'adminAlert',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
