import actionType from './const';

/**
 * ACTION METHOD: USER ACTION METHOD;
 * 1. FETCH
 * 2. SUCCESS
 * 3. FAILED
 */
// CODE START | ACTION METHOD
export const reduxShowLoading = () => ({
  type: actionType.SHOW_LOADING
});
export const reduxHideLoading = () => ({
  type: actionType.HIDE_LOADING
});
// CODE END | ACTION METHOD

/**
 * ACTION API METHOD: USER API METHOD
 * @param {*} payload : dinamic data;
 */
// CODE START | ACTION API METHOD
