import actionTypes from './const';

/**
 * ACTION METHOD: TOAST ACTION METHOD
 */
// CODE START | TOAST ACTION METHOD;

export const reduxReqSetToast = value => ({
  type: actionTypes.SET_TOAST,
  value
});

// CODE END | TOAST ACTION METHOD
