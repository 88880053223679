/* eslint-disable import/no-cycle */
import actionType from './const';
import { reduxApiRequest } from '../request/action';

export const setKarantina = value => ({
  type: actionType.SET_KARANTINA,
  value
});

export const setKarantinaHistory = value => ({
  type: actionType.SET_KARANTINA_HISTORY,
  value
});

export const setReportHarian = value => ({
  type: actionType.SET_REPORT,
  value
});

export const clearKarantina = () => ({
  type: actionType.CLEAR_KARANTINA
});

export const setKarantinaOpen = value => ({
  type: actionType.SET_KARANTINA_OPEN,
  value
});

export const clearKarantinaOpen = () => ({
  type: actionType.CLEAR_KARANTINA_OPEN
});

export const setKarantinaOnprogress = value => ({
  type: actionType.SET_KARANTINA_ONPROGRESS,
  value
});

export const clearKarantinaOnprogress = () => ({
  type: actionType.CLEAR_KARANTINA_ONPROGRESS
});

export const setKarantinaCompleted = value => ({
  type: actionType.SET_KARANTINA_COMPLETED,
  value
});

export const clearKarantinaCompleted = () => ({
  type: actionType.CLEAR_KARANTINA_COMPLETED
});

export const resetReducerKarantina = () => ({
  type: actionType.RESET_REDUCER_KARANTINA
});

/**
 * DEPRECATED CODE
 * @param {*} payload
 */

export const fetchHistoryKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientKarantinaProses',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setKarantinaHistory(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchKarantinaDalamProses = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientKarantinaProses',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setKarantina(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchIkhKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getIkhKarantina',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setKarantina(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchOpenedKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getIkhKarantina',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setKarantina(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchDetailKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientKarantinaProses',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchCreatePemeriksaanAwal = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'createPemeriksaanAwal',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchPemantuanHarian = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getPemantuanHarian',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setReportHarian(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchCreateReportPengamatan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'createReportHarian',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
export const fetchAdminKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientKarantina',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setKarantina(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchClientKarantinaId = (
  id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'getClientKarantina',
    ...(id && { path: `${id}` }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchCreatePengajuanLab = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'createIkhPengajuanLab',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));

    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchCreateKajiUlangKesiapan = (
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'createKajiUlang',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchCreatePelepasan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'createPelepasan',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchClientKarantinaTestLab = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientKarantinaTestLab',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchCreateLabPengujian = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'createLabPengujian',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
