import actionType from './const';
import { reduxApiRequest } from '../request/action';

export const setJenisPenanganan = value => ({
  type: actionType.SET_JENIS_PENANGANAN,
  value
});

export const clearJenisPenanganan = () => ({
  type: actionType.CLEAR_JENIS_PENANGANAN
});

export const fetchJenisPenanganan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getPenanganan',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setJenisPenanganan(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchJenisPenangananById = (
  Id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'getPenanganan',
    ...(Id && { path: Id }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchAddJenisPenanganan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'addPenanganan',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchUpdateJenisPenanganan = (
  Id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'updatePenanganan',
    ...(Id && { path: Id }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchDeleteJenisPenanganan = (
  Id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'deletePenanganan',
    ...(Id && { path: Id }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
