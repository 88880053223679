import actionType from './const';

const initialState = {
  isVisible: false
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SHOW_LOADING:
      return {
        ...state,
        isVisible: true
      };
    case actionType.HIDE_LOADING:
      return {
        ...state,
        isVisible: false
      };
    default:
      return state;
  }
};

export default loading;
