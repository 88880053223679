/* eslint-disable import/no-cycle */
import API from 'configs/api';
import { _ } from 'libraries';
import actionType from './const';
import { reduxReqSetToast } from '../toast/action';
import { reduxHideLoading, reduxShowLoading } from '../loading/action';

export const clearRequest = () => ({
  type: actionType.CLEAR_REQUEST
});

const reduxCreateRequest = ({ api = null, type = null, value = null }) => ({
  api,
  type,
  value
});

/**
 *  DINAMYC REDUX API REQUEST
 * @param { api, type, response, ...payload} payload;
 */

export const reduxApiRequest = (
  payload = {},
  successToast = true,
  showLoading = true
) => dispatch => {
  if (showLoading) dispatch(reduxShowLoading());
  return API[payload.api](payload)
    .then(response => {
      if (response && response.status) {
        if (showLoading) dispatch(reduxHideLoading());
        const method = _.get(response, 'method', null);
        if (method && method !== 'GET' && successToast) {
          dispatch(
            reduxReqSetToast({
              title: 'Success!',
              message: _.get(response, 'message', 'Berhasil'),
              level: 'success'
            })
          );
        }
        return response;
      }
    })
    .catch(err => {
      console.log(err);
      if (showLoading) dispatch(reduxHideLoading());

      dispatch(
        reduxReqSetToast({
          title: 'Error!',
          message: _.get(err, 'message', 'Terjadi kesalahan'),
          level: 'error'
        })
      );
      throw err;
    });
};
