/**
 * ACTION TYPE: POST ACTION TYPE;
 */
// CODE START | ACTION TYPE;
const actionType = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING'
};
// CODE END | ACTION TYPE;

export default actionType;
