import actionType from './const';
import { reduxApiRequest } from '../request/action';

export const setPetugasKH = value => ({
  type: actionType.SET_PETUGAS_KH,
  value
});

export const setTempatTugas = value => ({
  type: actionType.SET_TEMPAT_TUGAS,
  value
});

export const setSelectedTempatTugas = value => ({
  type: actionType.SET_SELECTED_TEMPAT_TUGAS,
  value
});

export const clearPetugasKH = () => ({
  type: actionType.CLEAR_PETUGAS_KH
});

export const fetchPetugasKH = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getPetugasKH',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setPetugasKH(data));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchGetClientPetugasKH = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientPetugasKH',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setPetugasKH(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchPetugasKhById = (
  id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'getPetugasKH',
    ...(id && { path: id }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchCreatePetugasKH = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'addPetugasKH',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const fetchUpdatePetugasKH = (
  id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'updatePetugasKH',
    ...(id && { path: id }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeletePetugasKH = (
  id = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'deletePetugasKH',
    ...(id && { path: id }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    console.log(err);
  }
};

/**
 * get daftar tempat tugas
 */
export const fetchGetTempatTugas = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getTempatTugas',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setTempatTugas(data));
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

/**
 * get daftar tempat tugas
 */
export const fetchClientGetTempatTugas = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientTempatTugas',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setTempatTugas(data));
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

/**
 * Create new tempat tugas
 */
export const fetchCreateTempatTugas = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'createTempatTugas',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * update new tempat tugas
 */
export const fetchUpdateTempatTugas = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'updateTempatTugas',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * update new tempat tugas
 */
export const fetchDeleteTempatTugas = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'deleteTempatTugas',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
