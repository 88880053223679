/* eslint-disable import/no-cycle */
import ApiRequest from "./config";
import baseUrl from "./url";

const API = {};

/**
 * Authentication
 */
API.login = ApiRequest.post(baseUrl.login);

/**
 * Profile
 */
API.profile = ApiRequest.get(baseUrl.profile, true);
API.changeProfile = ApiRequest.post(baseUrl.profile, true);
API.changePassword = ApiRequest.put(baseUrl.profile, true);

/**
 * User creations
 */
API.users = ApiRequest.get(baseUrl.v2.users, true);
API.createUser = ApiRequest.post(baseUrl.v2.users, true);
API.editUser = ApiRequest.post(baseUrl.v2.users, true);
API.changeUserStatus = ApiRequest.put(baseUrl.v2.users, true);
API.deleteUser = ApiRequest.delete(baseUrl.v2.users, true);

/**
 * Log Activity
 */
API.getActivities = ApiRequest.get(baseUrl.v2.activities, true);

API.alert = ApiRequest.post(baseUrl.v2.alert, true);

/**
 * Permohonan
 */
API.permohonan = ApiRequest.get(baseUrl.v2.permohonan, true);
API.createPermohonan = ApiRequest.post(baseUrl.v2.permohonan, true);
API.updatePermohonan = ApiRequest.put(baseUrl.v2.permohonan, true);

/**
 * Karantina
 */
API.karantina = ApiRequest.get(baseUrl.v2.karantina, true);
API.penangananKarantina = ApiRequest.get(baseUrl.v2.penangananKarantina, true);
API.createPenangananKarantina = ApiRequest.post(
  baseUrl.v2.penangananKarantina,
  true
);
API.updatePenangananKarantina = ApiRequest.put(
  baseUrl.v2.penangananKarantina,
  true
);
API.deletePenangananKarantina = ApiRequest.delete(
  baseUrl.v2.penangananKarantina,
  true
);
API.getItemKarantina = ApiRequest.get(baseUrl.admin.itemKarantina, true);
API.addItemKarantina = ApiRequest.post(baseUrl.admin.itemKarantina, true);
API.updateItemKarantina = ApiRequest.put(baseUrl.admin.itemKarantina, true);
API.updateItemStatusKarantina = ApiRequest.put(
  baseUrl.admin.itemKarantina,
  true
);
API.deleteItemKarantina = ApiRequest.delete(baseUrl.admin.itemKarantina, true);
API.getPenanganan = ApiRequest.get(baseUrl.client.penanganan, true);
API.addPenanganan = ApiRequest.post(baseUrl.admin.penanganan, true);
API.updatePenanganan = ApiRequest.put(baseUrl.admin.penanganan, true);
API.deletePenanganan = ApiRequest.delete(baseUrl.admin.penanganan, true);
API.getClientKarantinaProses = ApiRequest.get(baseUrl.client.karantina, true);
API.getClientKarantinaTestLab = ApiRequest.get(baseUrl.client.karantina, true);
API.createPemeriksaanAwal = ApiRequest.post(baseUrl.v2.pemeriksaan, true);
API.getPemantuanHarian = ApiRequest.get(baseUrl.ikh.pemantuanHarian, true);
API.createReportHarian = ApiRequest.post(baseUrl.ikh.pemantuanHarian, true);
API.createIkhPengajuanLab = ApiRequest.post(baseUrl.ikh.pengajuanLab, true);
API.createPelepasan = ApiRequest.post(baseUrl.ikh.pelepasan, true);
API.getIkhPermohonan = ApiRequest.get(baseUrl.ikh.permohonan, true);
API.getIkhKarantina = ApiRequest.get(baseUrl.ikh.karantina, true);
API.getPenerimaanHewan = ApiRequest.get(baseUrl.ikh.penerimaanHewan, true);
API.createKajiUlang = ApiRequest.post(baseUrl.lab.kajiulang, true);
API.responPermohonan = ApiRequest.post(baseUrl.lab.responPermohonan, true);
API.createLabPengujian = ApiRequest.post(baseUrl.lab.pengujian, true);

/**
 * Petugas dan Tempat Tugas
 */
API.getPetugasKH = ApiRequest.get(baseUrl.admin.petugasKH, true);
API.addPetugasKH = ApiRequest.post(baseUrl.admin.petugasKH, true);
API.updatePetugasKH = ApiRequest.put(baseUrl.admin.petugasKH, true);
API.deletePetugasKH = ApiRequest.delete(baseUrl.admin.petugasKH, true);
API.getTempatTugas = ApiRequest.get(baseUrl.admin.tempatTugas, true);
API.createTempatTugas = ApiRequest.post(baseUrl.admin.tempatTugas, true);
API.updateTempatTugas = ApiRequest.put(baseUrl.admin.tempatTugas, true);
API.deleteTempatTugas = ApiRequest.delete(baseUrl.admin.tempatTugas, true);

/**
 * IKH & LAB
 */
API.getIkhLab = ApiRequest.get(baseUrl.admin.ikhLab, true);
API.addIkhLab = ApiRequest.post(baseUrl.admin.ikhLab, true);
API.updateIkhLab = ApiRequest.put(baseUrl.admin.ikhLab, true);
API.deleteIkhLab = ApiRequest.delete(baseUrl.admin.ikhLab, true);

/**
 * Pengajuan
 */
API.getPengajuanAdmin = ApiRequest.get(baseUrl.admin.pengajuan, true);
API.createPengajuanAdmin = ApiRequest.post(baseUrl.admin.pengajuan, true);
API.deletePengajuanAdmin = ApiRequest.delete(baseUrl.admin.pengajuan, true);
API.updatePengajuanAdmin = ApiRequest.put(baseUrl.admin.pengajuan, true);
API.adminAlert = ApiRequest.put(baseUrl.admin.alert, true);
API.pengajuanPetugas = ApiRequest.get(baseUrl.pengajuan.petugas, true);
API.pengajuanJenisItem = ApiRequest.get(baseUrl.pengajuan.jenisItem, true);
API.getClientItemKarantina = ApiRequest.get(baseUrl.client.itemKarantina, true);
API.getClientPetugasKH = ApiRequest.get(baseUrl.client.petugasKH, true);
API.getClientTempatTugas = ApiRequest.get(baseUrl.client.tempatTugas, true);
API.getClientKarantina = ApiRequest.get(baseUrl.client.karantina, true);
API.getPengajuanDaftarPermohonan = ApiRequest.get(
  baseUrl.pengajuan.permohonan,
  true
);
API.createPengajuanFormPermohonan = ApiRequest.post(
  baseUrl.v2.permohonan,
  true
);
API.updatePengajuanFormPermohonan = ApiRequest.put(baseUrl.v2.permohonan, true);

/**
 * Notification
 */
API.getNotification = ApiRequest.get(baseUrl.client.notification, true);
API.readNotification = ApiRequest.post(baseUrl.client.notification, true);

export default API;
