/* eslint-disable import/no-cycle */
import { API } from 'configs';
import { store, setActtivity } from 'modules';
import { handleRequest } from '../helper';

/**
 * a service call for get activites
 * @param {*} payload
 */
export const getActivities = async (payload = {}) => {
  try {
    store.dispatch(setActtivity({ isLoading: true, isError: false }));
    const res = await handleRequest(API.getActivities(payload));

    const { data } = res;

    store.dispatch(setActtivity({ isLoading: false, isError: false, ...data }));

    return data;
  } catch (err) {
    store.dispatch(setActtivity({ isLoading: false, isError: true }));
    throw err;
  }
};
