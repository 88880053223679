/* eslint-disable import/no-cycle */
import actionType from './const';
import { reduxApiRequest } from '../request/action';
// import { store } from '../../store';

export const setFormPengajuan = value => ({
  type: actionType.SET_PERMOHONAN,
  value
});

export const clearFormPengajuan = () => ({
  type: actionType.CLEAR_PERMOHONAN
});

export const setPermohonanPending = value => ({
  type: actionType.SET_PERMOHONAN_PENDING,
  value
});

export const clearPermohonanPending = () => ({
  type: actionType.CLEAR_PERMOHONAN_PENDING
});

export const setPermohonanApproved = value => ({
  type: actionType.SET_PERMOHONAN_APPROVED,
  value
});

export const clearPermohonanApproved = () => ({
  type: actionType.CLEAR_PERMOHONAN_APPROVED
});

export const setPermohonanRejected = value => ({
  type: actionType.SET_PERMOHONAN_REJECTED,
  value
});

export const clearPermohonanRejected = () => ({
  type: actionType.CLEAR_PERMOHONAN_REJECTED
});

export const resetReducerPermohonan = () => ({
  type: actionType.RESET_REDUCER_PERMOHONAN
});

export const fetchPengajuanDaftarPermohonan = (
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'getPengajuanDaftarPermohonan',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setFormPengajuan(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchPengajuanDetailPermohonan = (
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'getPengajuanDaftarPermohonan',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchIkhDetailPermohonan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getIkhPermohonan',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchPengajuanCreateFormPermohonan = (
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'createPengajuanFormPermohonan',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchPengajuanUpdateFormPermohonan = (
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'updatePengajuanFormPermohonan',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchAdminPengajuan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getPengajuanAdmin',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setFormPengajuan(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchAdminDetailPengajuan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getPengajuanAdmin',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchAdminUpdatePengajuan = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'updatePengajuanAdmin',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));

    return response;
  } catch (err) {
    throw err;
  }
};
