import actionType from './const';

const initState = {
  all: []
};

const jenisPenanganan = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_JENIS_PENANGANAN:
      return {
        ...state,
        all: action.value
      };
    case actionType.CLEAR_JENIS_PENANGANAN:
      return initState;
    default:
      return state;
  }
};

export default jenisPenanganan;
