import actionTypes from './const';

const initialState = {
  level: 'info',
  message: 'Toast message'
};

const toast = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOAST:
      return {
        ...state,
        ...action.value
      };
    case actionTypes.REMOVE_TOAST: {
      return initialState;
    }
    default:
      return state;
  }
};

export default toast;
