/**
 * ACTION TYPE: TOAST ACTION TYPE;
 */
// CODE START | TOAST ACTION TYPE;
const actionTypes = {
  SET_TOAST: 'SET_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST'
};
// CODE END | TOASR ACTION TYPE;

export default actionTypes;
