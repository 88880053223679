/* eslint-disable import/no-cycle */
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/database';
import {
  setNotification,
  store,
  clearNotification,
  setInbox,
  clearInbox
} from 'modules';
import { Tools } from 'utils';

export const userNotificationFirebase = async (
  userId,
  notificationData = []
) => {
  try {
    await firebase
      .database()
      .ref(`/userNotifications/${userId}`)
      .set({ notificationData });
  } catch (err) {
    throw err;
  }
};

export const getUserNotificationFirebase = userId => {
  firebase
    .database()
    .ref(`/userNotifications/${userId}`)
    .on('value', snapshot => {
      const result = snapshot.val();

      // console.log('result', result);

      if (result && result.notificationData) {
        const parsedResult = JSON.parse(result.notificationData);

        if (parsedResult && parsedResult.length > 0) {
          let inbox = parsedResult;
          const notif = [];

          inbox = inbox.map(val => {
            if (val.isRead === 0) {
              notif.push(val);
            }
            return val;
          });

          store.dispatch(setNotification(notif));
          store.dispatch(setInbox(inbox));
        }
      } else {
        store.dispatch(clearNotification());
        store.dispatch(clearInbox());
      }
    });
};

export const getFirebaseNotification = (type, userId) => {
  firebase
    .database()
    .ref(`/notifications/${type}`)
    // .orderByChild('isRead')
    // .equalTo(0)
    .limitToLast(200)
    .on('value', snapshot => {
      // console.log('snapshot', snapshot.val());

      const result = snapshot.val();

      if (result) {
        const notif = [];
        const inbox = Object.keys(result)
          .map(key => {
            const value = {
              ...result[key],
              timestamp: key
            };

            if (value.isRead === 0) {
              notif.push(value);
            }

            return value;
          })
          .reverse();

        // userNotificationFirebase(userId, JSON.stringify(inbox));

        store.dispatch(setNotification(notif));
        store.dispatch(setInbox(inbox));
      } else {
        store.dispatch(clearNotification());
        store.dispatch(clearInbox());
        // Tools.deleteInbox();
      }
    });
};

export const removeNotificationListener = () =>
  firebase
    .database()
    .ref('/notifications')
    .off();

/**
 *
 * @param {*} type [ admin, ikh, pengajuan, lab ]
 * @param {*} payload { title, description, status, is_read, notifKey, createdAt, updatedAt }
 */
export const createFirebaseNotification = async (type, payload = {}) => {
  try {
    const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
    const updatedAt = createdAt;
    const response = await firebase
      .database()
      .ref(`/notifications/${type}/${moment().format('x')}`)
      .set({
        ...payload,
        createdAt,
        updatedAt
      });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateFirebaseNotification = async (type, notifKey, payload) => {
  const dataRef = firebase
    .database()
    .ref(`/notifications/${type}`)
    .orderByChild('isRead')
    .equalTo(0);
  try {
    const existSnapshot = await dataRef.once('value');

    let data = existSnapshot.val();

    if (!data) return null;

    data = Object.keys(data).map(async key => {
      if (parseInt(notifKey) === parseInt(data[key].notifKey)) {
        await firebase
          .database()
          .ref(`/notifications/${type}/${key}`)
          .update({
            ...data[key],
            isRead: 1
          });
      }
      return {
        ...data[key],
        isRead: 1
      };
    });

    // console.log('data', data);

    return data;
  } catch (err) {
    throw err;
  }
};
