import actionType from './const';

const initialState = {
  all: [],
  inbox: []
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NOTIFICATION:
      return {
        ...state,
        all: action.value
      };

    case actionType.SET_INBOX:
      return {
        ...state,
        inbox: action.value
      };
    case actionType.CLEAR_NOTIFICATION:
      return {
        ...state,
        all: initialState.all
      };

    case actionType.CLEAR_INBOX:
      return {
        ...state,
        inbox: initialState.inbox
      };
    default:
      return state;
  }
};

export default notification;
