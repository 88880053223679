import actionType from './const';
import { reduxApiRequest } from '../request/action';

export const setItemKarantina = value => ({
  type: actionType.SET_ITEM_KARANTINA,
  value
});

export const clearItemKarantina = () => ({
  type: actionType.CLEAR_ITEM_KARANTINA
});

export const fetchItemKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getItemKarantina',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setItemKarantina(data));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchGetClientItemKarantina = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'getClientItemKarantina',
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    dispatch(setItemKarantina(data));
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchItemKarantinaById = (
  itemId = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'getItemKarantina',
    ...(itemId && { path: itemId }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchChangeStatusItem = (
  itemId = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'updateItemStatusKarantina',
    ...(itemId && { path: `${itemId}/status` }),
    ...payload
  };
  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchCreateItem = (payload = {}) => async dispatch => {
  const requestPayload = {
    api: 'addItemKarantina',
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchUpdateItem = (
  itemId = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'updateItemKarantina',
    ...(itemId && { path: itemId }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchDeleteItem = (
  itemId = null,
  payload = {}
) => async dispatch => {
  const requestPayload = {
    api: 'deleteItemKarantina',
    ...(itemId && { path: itemId }),
    ...payload
  };

  try {
    const response = await dispatch(reduxApiRequest(requestPayload));
    return response;
  } catch (err) {
    throw err;
  }
};
