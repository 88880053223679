import actionType from './const';

const initState = {
  all: []
};

const itemKarantina = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_ITEM_KARANTINA:
      return {
        ...state,
        all: action.value
      };
    case actionType.CLEAR_ITEM_KARANTINA:
      return initState;
    default:
      return state;
  }
};

export default itemKarantina;
