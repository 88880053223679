/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { moment, _ } from "libraries";
import { updateFirebaseNotification } from "services";
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

export * from "../storage";

/**
 * @name typeOf
 * @description untuk cek tipe data dalam bentuk apa
 * @param {*} data 
 */
export const typeOf = data => {
  if (typeof data === "undefined") {
    return "undefined";
  }
  if (data === null) {
    return null;
  }
  if (data.constructor === [].constructor) {
    return "array";
  }
  if (data.constructor === {}.constructor) {
    return "object";
  }
  return typeof data;
};

/**
 * @name isCanParseJson
 * @description untuk cek apakah string bisa di parse ke json
 * @param {*} str 
 */
export const isCanParseJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * @name isCanStringifyJson
 * @description untuk cek apakah data bisa di stringify
 * @param {*} str 
 */
export const isCanStringifyJson = data => {
  try {
    JSON.stringify(data);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * @name setStorage
 * @description sortcut untuk simpen ke localStorage dengan stringify
 * @param {*} key 
 * @param {*} data 
 */
export const setStorage = (key, data) => {
  if (isCanStringifyJson(data) && typeof data !== "string") {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  }
  localStorage.setItem(key, data);
};


/**
 * @name getStorage
 * @description sortcut untuk get data localStorage
 * @param {*} key 
 */
export const getStorage = key => {
  const storage = localStorage.getItem(key);
  if (isCanParseJson(storage)) {
    return JSON.parse(storage);
  }
  return storage;
};

/**
 * @name isLogin
 * @description untuk ambil status userData sebagai login dari localStorage
 */
export const isLogin = () => {
  const userData = getStorage("userData");
  if (userData && userData.user) {
    return true;
  }
  return false;
};

/**
 * @name getLastArray
 * @description untuk ambil data array paling terakhir
 * @param {*} arr 
 */
export const getLastArray = arr => {
  if (!typeOf(arr) === "array") {
    throw new Error("require data is array");
  }
  if (arr && arr.length) {
    return arr[arr.length - 1];
  }
  return arr;
};


/**
 * @name getParamsToObject
 * @description untuk ambil data params di url dan parse ke data bentuk object
 * @param {*} getParams 
 */
export const getParamsToObject = (getParams = "default=0") => {
  if (getParams.indexOf("=") > -1) {
    return JSON.parse(
      `{"${decodeURI(getParams.substr(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  }
  return {
    default: "0"
  };
};

/**
 * @name objectToParams
 * @description untuk parse data object ke params url
 * @param {*} data 
 */
export const objectToParams = data => {
  let param = "";
  if (typeOf(data) === "undefined") {
    return param;
  }
  if (typeOf(data) !== "object") {
    throw new Error("data must be object");
  }
  for (const key in data) {
    param += param === "" ? "?" : "&";
    param += `${key}=${encodeURIComponent(data[key])}`;
  }
  return param;
};

/**
 * @name isAdmin
 * @description untuk cek status user sebagai admin
 * @param {*} profile 
 */
export const isAdmin = profile => {
  let admin = false;
  if (profile) {
    const { roles } = profile;
    if (roles && roles.length > 0) {
      roles.forEach(role => {
        if (role.name === "admin") {
          admin = true;
        }
      });
    }
  }
  return admin;
};

/**
 * @name renderTypeToTitle
 * @description untuk handle title aplikasi SIMLABKH
 * @param {*} type 
 */
export const renderTypeToTitle = type => {
  switch (type) {
    case "admin":
      return "Admin";
    case "ikh":
      return "IKH";
    case "lab":
      return "LAB";
    case "pengajuan":
      return "Pengajuan";
    default:
      return null;
  }
};

/**
 * @name getLayoutType
 * @description untuk handle layout dashboard aplikasi SIMLABKH
 * @param {*} type 
 */
export const getLayoutType = type => {
  switch (type) {
    case "admin":
    case "ikh":
    case "lab":
    case "pengajuan":
      return type;
    default:
      return null;
  }
};

/**
 * @name renderStatusBadge
 * @description untuk handle status badge
 * @param {*} status 
 */
export const renderStatusBadge = status => {
  switch (status) {
    case "active":
    case "approve":
      return "success";
    case "inactive":
    case "reject":
      return "danger";
    case "pending":
      return "primary";
    default:
      return "";
  }
};

/**
 * @name convertDate
 * @description untuk convertDate date ke format moment DD MMMM YYYY
 * @param {*} date 
 */
export const convertDate = date => {
  if (!date) return null;
  return moment(date).format("DD MMMM YYYY");
};

/**
 * @name readStatusKarantina
 * @description untuk membaca status karantina pada aplikasi
 * @param {*} status 
 */
export const readStatusKarantina = status => {
  const initialStatus = {
    badge: "",
    text: "",
    longText: ""
  };
  switch (status) {
    case 3:
      return {
        ...initialStatus,
        badge: "danger",
        text: "Terlambat",
        longText: "Terlambat Pelepasan"
      };
    case 2:
      return {
        ...initialStatus,
        badge: "primary",
        text: "Hari ini",
        longText: "Pelepasan Hari ini"
      };
    case 1:
      return {
        ...initialStatus,
        badge: "success",
        text: "Minggu ini",
        longText: "Pelepasan Minggu Ini"
      };
    case 0:
      return {
        ...initialStatus,
        badge: "info",
        text: "Karantina",
        longText: "Masih dalam karantina"
      };
    default:
      return initialStatus;
  }
};

export const initialKarantinaState = {
  Permohonan: {},
  Pemohon: {},
  Petugas: {},
  Item: {},
  hewan: [],
  Kendaraan: {},
  karantinaReport: [],
  dataPengajuanLab: {},
  rekomendasi: ""
};

export const mapDetailPermohonan = {};

/**
 * @name mapDetailKarantina
 * @description untuk mapping data karantina
 * @param {*} data 
 * @param {*} isPermohonan 
 */
export const mapDetailKarantina = (data, isPermohonan = false) => {
  if (!data) return initialKarantinaState;
  const {
    pemohon,
    petugas,
    item,
    item_karantina,
    hewan,
    kendaraan,
    karantina_report,
    kajiulang,
    testlab,
    pengujian_lab,
    karantina,
    penanganan,
    penangananKarantina
  } = data;

  if (isPermohonan)
    return {
      ...initialKarantinaState,
      Permohonan: {
        id: data.id,
        no_reg: data.no_reg,
        uniq_id: data.uniq_id,
        permohonanDate: data.permohonan_date,
        status: data.status,
        petugas_id: data.petugas_id,
        pemohon_id: data.pemohon_id
      },
      Pemohon: {
        name: pemohon.name,
        card_type: pemohon.card_type,
        card_id: pemohon.card_id,
        address: pemohon.address
      },
      Petugas: {
        name: petugas.name,
        nip: petugas.nip,
        tempat_tugas: petugas.tempat_tugas.name
      },
      hewan,
      karantina,
      testlab,
      pengujian_lab,
      Kendaraan: {
        type: kendaraan.type,
        plat_number: kendaraan.plat_number,
        address_from: kendaraan.address_from,
        address_to: kendaraan.address_to,
        arrival_date: kendaraan.arrival_date,
        arrival_place: kendaraan.arrival_place,
        attachment: kendaraan.attachment
      },
      penanganan,
    };

  return {
    ...initialKarantinaState,
    Permohonan: {
      no_reg: data.no_reg,
      uniq_id: data.uniq_id,
      permohonanDate: data.permohonan.permohonan_date,
      status: data.status,
      // item_id: data.item_id,
      petugas_id: data.petugas_id,
      // item_karantina_id: data.item_karantina_id,
      pemohon_id: data.pemohon_id
    },
    Pemohon: {
      name: pemohon.name,
      card_type: pemohon.card_type,
      card_id: pemohon.card_id,
      address: pemohon.address
    },
    Petugas: {
      name: petugas.name,
      nip: petugas.nip,
      tempat_tugas: petugas.tempat_tugas.name
    },
    hewan,
    ...(item && {
      Item: {
        name: item.name,
        jenisItem: item_karantina.name,
        amount: item.amount,
        unit: item.unit,
        gender: item.gender,
        color: item.color,
        age: item.age,
        microchip: item.microchip,
        info: item.info,
        ras: item.ras
      }
    }),
    Kendaraan: {
      type: kendaraan.type,
      plat_number: kendaraan.plat_number,
      address_from: kendaraan.address_from,
      address_to: kendaraan.address_to,
      arrival_date: kendaraan.arrival_date,
      arrival_place: kendaraan.arrival_place,
      attachment: kendaraan.attachment
    },
    karantinaReport: karantina_report,
    ...(kajiulang && {
      dataPengajuanLab: {
        namaPemilik: pemohon.name,
        // jenisItem: item_karantina.name,
        tanggalPenerimaan:
          kajiulang.created_at &&
          moment(kajiulang.created_at).format("DD MMMM YYYY"),
        metodePengujian: testlab.metode_pengujian,
        jumlahSampel: testlab.jumlah_sampel,
        namaKomoditas: testlab.nama_komoditas,
        targetPengujian: testlab.target_pengujian,
        pengujianLab: pengujian_lab,
        waktuPengambilanSampel:
          testlab.waktu_pengambilan &&
          moment(testlab.waktu_pengambilan).format("HH:mm, DD MMMM YYYY"),
        kondisiSampel: testlab.kondisi_sampel,
        asalUpt: testlab.asal_upt,
        pemantauanAsal: testlab.pemantauan_asal,
        jenisPengujianHPH: testlab.jenis_pengujian,
        kesimpulan: testlab.kesimpulan
      }
    }),
    penangananKarantina,
    rekomendasi: data.recommendation
  };
};

export const handleReadNotif = async props => {
  const { history, layoutType } = props;
  const search = _.get(history, "location.search", null);
  const params = getParamsToObject(search);
  if (params.has_notif && params.notif_key) {
    updateFirebaseNotification(layoutType, params.notif_key);
    return true;
  }
};

export const filterData = (data = [], keyword) =>
  data.filter(
    value =>
      value.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.no_reg.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );

export const filterDataUser = (data = [], keyword) =>
  data.filter(
    value =>
      value.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.email.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.employee_id.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
    // value.card_id.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );

export const filterLab = (data = [], keyword) =>
  data.filter(
    value =>
      value.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.phone.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.address.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );

export const filterItemKarantina = (data = [], keyword) =>
  data.filter(
    value => value.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );

export const filterPermohonan = (data = [], keyword) =>
  data.filter(
    value =>
      value.no_reg.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      (value.pemohon &&
        value.pemohon.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
  );

export const filterPenanganan = (data = [], keyword) =>
  data.filter(
    value => value.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );

export const filterPetugas = (data = [], keyword) =>
  data.filter(
    value =>
      value.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.nip.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      value.place.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );

/**
 * handle async short hand
 */
export const handleAsync = async promise => {
  try {
    const res = await promise;

    return [res, undefined];
  } catch (err) {
    return [undefined, err];
  }
};

export const handleChangePage = (page, callback) => {
  const payload = {
    params: {
      page
    }
  };

  console.log("chnage page", page);

  if (callback) callback(payload);
};

export const handleChangeRowsPerPage = (paginate, page, callback) => {
  const payload = {
    params: {
      per_page: paginate,
      page
    }
  };

  if (callback) callback(payload);
};

export const renderIkhVisibility = stat => {
  if (stat === "self") {
    return "Hanya melihat karantina di IKH-nya sendiri saja";
  }

  return "Dapat melihat semua proses karantina";
};
