import appEnv from '../appConfig';

export const config = appEnv;

const baseUrl = {
  // login
  login: `${config.url.api}/v1/login`,
  profile: `${config.url.api}/v1/profile`,

  // admin
  admin: {
    users: `${config.url.api}/v2/users`,
    activities: `${config.url.api}/v2/activities`,
    itemKarantina: `${config.url.api}/v1/admin/items`,
    penanganan: `${config.url.api}/v1/admin/penanganan`,
    petugasKH: `${config.url.api}/v1/admin/petugas`,
    ikhLab: `${config.url.api}/v1/admin/ikhlab`,
    tempatTugas: `${config.url.api}/v1/admin/tempat-tugas`,
    pengajuan: `${config.url.api}/v1/admin/permohonan`,
    alert: `${config.url.api}/v1/admin/alert`
  },

  // pengajuan
  pengajuan: {
    permohonan: `${config.url.api}/v1/pengajuan/permohonan`,
    petugas: `${config.url.api}/v1/pengajuan/petugas`,
    jenisItem: `${config.url.api}/v1/pengajuan/jenis-item`
  },
  // client
  client: {
    itemKarantina: `${config.url.api}/v1/client/item-karantina`,
    petugasKH: `${config.url.api}/v1/client/petugas`,
    tempatTugas: `${config.url.api}/v1/client/tempat-tugas`,
    karantina: `${config.url.api}/v1/client/karantina`,
    notification: `${config.url.api}/v1/client/notification`,
    penanganan: `${config.url.api}/v1/client/penanganan`
  },

  // IKH
  ikh: {
    permohonan: `${config.url.api}/v1/ikh/permohonan`,
    karantina: `${config.url.api}/v1/ikh/karantina`,
    penerimaanHewan: `${config.url.api}/v1/ikh/penerimaan-hewan`,
    pemeriksaanAwal: `${config.url.api}/v1/ikh/pemeriksaan`,
    pemantuanHarian: `${config.url.api}/v1/ikh/karantina/report`,
    pengajuanLab: `${config.url.api}/v1/ikh/testlab`,
    pelepasan: `${config.url.api}/v1/ikh/pelepasan`
  },
  lab: {
    responPermohonan: `${config.url.api}/v1/lab/respon-permohonan`,
    kajiulang: `${config.url.api}/v1/lab/kajiulang`,
    pengujian: `${config.url.api}/v1/lab/pengujian`
  },

  v2: {
    users: `${config.url.api}/v2/users`,
    activities: `${config.url.api}/v2/activities`,
    permohonan: `${config.url.api}/v2/permohonan`,
    karantina: `${config.url.api}/v2/karantina`,
    penangananKarantina: `${config.url.api}/v2/penanganan-karantina`,
    alert: `${config.url.api}/v2/alert`,
    pemeriksaan: `${config.url.api}/v2/pemeriksaan`
  }
};

export default baseUrl;
