import actionType from './const';

const initState = {
  data: {
    isLoading: false,
    isError: false,
    data: []
  }
};

const activity = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_ACTIVITY:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.value
        }
      };
    case actionType.CLEAR_ACTIVITY:
      return {
        ...state,
        data: initState.data
      };
    default:
      return state;
  }
};

export default activity;
