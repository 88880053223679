import { createSelector } from 'reselect';

export const tempatTugasSelector = state => state.petugasKH.tempatTugas;
export const petugasSelector = state => state.petugasKH.all;
export const selectedTempatTugas = state => state.petugasKH.selectedTempatTugas;

export const petugasByTempatSelector = createSelector(
  [petugasSelector, selectedTempatTugas],
  (petugas, selected) => {
    if (selectedTempatTugas) {
      return petugas.filter(val => {
        if (
          val.tempat_tugas &&
          val.tempat_tugas.id &&
          selected &&
          val.tempat_tugas.id === selected.id
        ) {
          return true;
        }

        return false;
      });
    }

    return [];
  }
);
