import { API } from 'configs';
import { resetReducer, store } from 'modules';
import { Tools } from 'utils';
import { removeNotificationListener } from '../firebase';

/**
 * a Login Service
 * @param {*} payload require path and body
 */
export const login = async payload => {
  const [res, err] = await Tools.handleAsync(API.login(payload));
  if (err) {
    throw err;
  }

  const { data } = res;

  if (data) {
    const { token } = data;
    Tools.setAuthToken(token);
  }

  return res;
};

/**
 * a Logout Service
 */
export const logout = () => {
  store.dispatch(resetReducer());
  Tools.deleteAuthToken();
  removeNotificationListener();
};
