import _ from 'lodash';
import {
  reduxHideLoading,
  reduxShowLoading,
  reduxReqSetToast,
  store
} from 'modules';
import { Tools } from 'utils';

const requestOption = {
  showLoading: true,
  showToast: true
};

export const toast = value => store.dispatch(reduxReqSetToast(value));

/**
 * handleRequest
 */
export const handleRequest = async (apiRequest, option = {}) => {
  const configOption = {
    ...requestOption,
    ...option
  };

  if (configOption.showLoading) store.dispatch(reduxShowLoading());

  const [res, err] = await Tools.handleAsync(apiRequest);

  if (configOption.showLoading) store.dispatch(reduxHideLoading());

  if (err) {
    toast({
      title: 'Error!',
      message: _.get(err, 'message', 'Terjadi kesalahan'),
      level: 'error'
    });
    throw err;
  }

  const status = _.get(res, 'status', null);
  const method = _.get(res, 'method', null);

  if (!status) {
    toast({
      title: 'Error!',
      message: 'Terjadi kesalahan',
      level: 'error'
    });
    return res;
  }

  if (method !== 'GET' && configOption.showToast) {
    toast({
      title: 'Success!',
      message: _.get(res, 'message', 'Berhasil'),
      level: 'success'
    });
  }

  return res;
};
