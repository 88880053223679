import actionType from './const';

const initState = {
  all: [],
  pendingData: {
    isLoading: false,
    isError: false,
    data: []
  },
  approvedData: {
    isLoading: false,
    isError: false,
    data: []
  },
  rejectedData: {
    isLoading: false,
    isError: false,
    data: []
  }
};

const permohonan = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_PERMOHONAN:
      return {
        ...state,
        all: action.value
      };
    case actionType.CLEAR_PERMOHONAN:
      return {
        ...state,
        all: []
      };
    case actionType.SET_PERMOHONAN_PENDING:
      return {
        ...state,
        pendingData: {
          ...state.pendingData,
          ...action.value
        }
      };
    case actionType.CLEAR_PERMOHONAN_PENDING:
      return {
        ...state,
        pendingData: initState.pendingData
      };
    case actionType.SET_PERMOHONAN_APPROVED:
      return {
        ...state,
        approvedData: {
          ...state.approvedData,
          ...action.value
        }
      };
    case actionType.CLEAR_PERMOHONAN_APPROVED:
      return {
        ...state,
        approvedData: initState.approvedData
      };
    case actionType.SET_PERMOHONAN_REJECTED:
      return {
        ...state,
        rejectedData: {
          ...state.rejectedData,
          ...action.value
        }
      };
    case actionType.CLEAR_PERMOHONAN_REJECTED:
      return {
        ...state,
        rejectedData: initState.rejectedData
      };
    case actionType.RESET_REDUCER_PERMOHONAN:
      return initState;
    default:
      return state;
  }
};

export default permohonan;
